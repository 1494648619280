import AuthGuard from 'app/auth/AuthGuard'
import NotFound from 'app/views/sessions/NotFound'
import chartsRoute from 'app/views/charts/ChartsRoute'
import materialRoutes from 'app/views/material-kit/MaterialRoutes'
import dashboardRoutes from 'app/views/dashboard/DashboardRoutes'
import dashboardbmRoutes from 'app/views/dashboardbm/DashboardbmRoutes'
import dashboardcreditRoutes from 'app/views/dashboardcredit/DashboardcreditRoutes'
import sessionRoutes from 'app/views/sessions/SessionRoutes'
import stateRoutes from 'app/views/states/StateRoutes'
import cityRoutes from 'app/views/cities/CityRoutes'
import employeeRoutes from 'app/views/employee/EmployeeRoutes'
import employeeRoutesNew from 'app/views/employeeNew/EmployeeRoutes'
import customerRoutes from 'app/views/customer/CustomerRoutes'
import customerRoutesNew from 'app/views/customerNew/CustomerRoutes'
import yardRoutes from 'app/views/yard/YardRoutes'
import rolemasterRoutes from 'app/views/rolemaster/RolemasterRoutes'
import menumasterRoutes from 'app/views/menumaster/MenumasterRoutes'
import menuaccessmasterRoutes from 'app/views/menuaccessmaster/MenuaccessmasterRoutes'
import skutypeRoutes from 'app/views/skutype/SkutypeRoutes'
import skupropertiesRoutes from 'app/views/skuproperties/SkupropertiesRoutes'
import skupropertiesdropdownRoutes from 'app/views/skupropertiesdropdown/SkupropertiesdropdownRoutes'
import skumappingRoutes from 'app/views/skumapping/SkumappingRoutes'
import uommasterRoutes from 'app/views/uommaster/UommasterRoutes'
import vehiclemasterRoutes from 'app/views/vehiclemaster/VehiclemasterRoutes'
import trucktypemasterRoutes from 'app/views/trucktypemaster/TrucktypemasterRoutes'
import formulamasterRoutes from 'app/views/formulamaster/FormulamasterRoutes'
import triggermasterRoutes from 'app/views/triggermaster/TriggermasterRoutes'
import stockauditRoutes from 'app/views/stockaudit/StockauditRoutes'
import timeconfiguratorRoutes from 'app/views/timeconfigurator/TimeconfiguratorRoutes'
import paymenttermsRoutes from 'app/views/paymentterms/PaymenttermsRoutes'
import stockholdRoutes from 'app/views/stockhold/StockholdRoutes'
import orderRoutes from 'app/views/order/OrderRoutes'
import stockinwardRoutes from 'app/views/stockinward/StockinwardRoutes'
import MatxLayout from '../components/MatxLayout/MatxLayout'
import skutypenewRoutes from 'app/views/skuTypeNew/SkutypenewRoutes'
import SkuMastersRoutes from 'app/views/SkuMasters/SkuMastersRoutes'
import SkuMastersRoutesNew from 'app/views/SkuMastersNew/SkuMastersRoutes'
import AccessManagementRoute from 'app/views/AccessManagement/AccessManagementRoute'
import ViewStockRoutes from 'app/views/ViewStock/ViewStockRoutes'
import BatchRoutes from 'app/views/batchclosing/BatchRoutes'
import DumpStockRoutes from 'app/views/dumpstock/DumpStockRoutes'
import LogisticRoutes from 'app/views/logisticOrder/LogisticRoutes'
import DistrictmasterRoutes from 'app/views/Districtmaster/DistrictmasterRoutes'
import ZonemasterRoutes from 'app/views/Zonemaster/ZonemasterRoutes'
import InvoiceRoutes from 'app/views/InvoiceOrder/InvoiceRoutes'
import CountrymasterRoutes from 'app/views/Countrymaster/CountrymasterRoutes'
import IntergodownTransferRoutes from 'app/views/IntergodownTransfer/IntergodownTransferRoutes'
import DashboardMenumasterRoutes from 'app/views/dashboardmenus/DashboardMenumasterRoutes'
import SalesReturnRoutes from 'app/views/salesReturn/SalesReturnRoutes'
import VendorUserRoutes from 'app/views/vendorser/VendorUserRoutes'
import WorkOrderRoutes from 'app/views/workorder/WorkOrderRoutes'
import YardWorkOrderRoutes from 'app/views/YardWorkOrder/YardWorkOrderRoutes'
import BMCreditFormRoutes from 'app/views/BMCreditForm/BMCreditFormRoutes'
import CustomerRecieptRoutes from 'app/views/CustomerReciept/CustomerRecieptRoutes'
import SupplierUserRoutes from 'app/views/SupplierUser/SupplierUserRoutes'
import ProfileRoutes from '../views/profile/ProfileRoutes'
import ProductWiseReportRoutes from '../views/ProductWiseReport/ProductWiseMasterRoutes'
import ProductSkuReportRoutes from '../views/ProductSkuReport/ProductSkuReportRoutes'
import DistrictWiseReportRoutes from '../views/DistrictWiseReport/DistrictWiseReportRoutes'
import DailyProductWiseSalesRoutes from '../views/DailyProductWiseSalesReport/DailyProductWiseSalesRoutes'
import DailyProductWisePurchaseRoutes from '../views/DailyProductWisePurchaseReport/DailyProductWisePurchaseReportRoutes'
import BatchWiseSkuWiseReportRoutes from '../views/BatchWise&SkuWiseReport/BatchWise&SkuWiseReportRoutes'
import utilizedAmountImportRoutes from '../views/utilizedAmountImport/utilizedAmountImportRoutes'
import batchClosingStockRoute from '../views/batchClosingStockReport/batchClosingStockRoute'
import StateWiseRateRoute from '../views/StateWiseRate/stateWiseRateRoute'
import tallyReportRout from '../views/tallyReport/tallyReportRout'
import salesReportRoute from '../views/salesReport/salesReportRoute'
import TransactionReportRoute from '../views/transactionReport/transactionReportRoute'
import AdditionalInwardRoutes from '../views/AdditionalInward/AdditionalInwardRoutes'
import OrderRevertRoute from '../views/OrderRevert/OrderRevertRoute'
import StockauditNewRoutes from 'app/views/StockAuditNew/StockauditNewRoutes'
import WorkOrderNewRoutes from '../views/workOrderNew/WorkOrderNewRoutes'

export const AllPages = () => {
    const all_routes = [
        {
            path: '/',
            element: (
                <AuthGuard>
                    <MatxLayout />
                </AuthGuard>
            ),
            children: [
                ...dashboardRoutes,
                ...dashboardbmRoutes,
                ...dashboardcreditRoutes,
                ...chartsRoute,
                ...materialRoutes,
                ...stateRoutes,
                ...cityRoutes,
                ...employeeRoutes,
                ...employeeRoutesNew,
                ...customerRoutes,
                ...customerRoutesNew,
                ...yardRoutes,
                ...rolemasterRoutes,
                ...menumasterRoutes,
                ...menuaccessmasterRoutes,
                ...skutypeRoutes,
                ...skupropertiesRoutes,
                ...skupropertiesdropdownRoutes,
                ...skumappingRoutes,
                ...uommasterRoutes,
                ...vehiclemasterRoutes,
                ...trucktypemasterRoutes,
                ...formulamasterRoutes,
                ...triggermasterRoutes,
                ...stockauditRoutes,
                ...timeconfiguratorRoutes,
                ...paymenttermsRoutes,
                ...stockholdRoutes,
                ...orderRoutes,
                ...stockinwardRoutes,
                ...skutypenewRoutes,
                ...SkuMastersRoutes,
                ...SkuMastersRoutesNew,
                ...AccessManagementRoute,
                ...ViewStockRoutes,
                ...BatchRoutes,
                ...DumpStockRoutes,
                ...LogisticRoutes,
                ...DistrictmasterRoutes,
                ...ZonemasterRoutes,
                ...InvoiceRoutes,
                ...CountrymasterRoutes,
                ...IntergodownTransferRoutes,
                ...InvoiceRoutes,
                ...DashboardMenumasterRoutes,
                ...SalesReturnRoutes,
                ...VendorUserRoutes,
                ...WorkOrderRoutes,
                ...YardWorkOrderRoutes,
                ...BMCreditFormRoutes,
                ...CustomerRecieptRoutes,
                ...SupplierUserRoutes,
                ...ProfileRoutes,
                ...ProductWiseReportRoutes,
                ...ProductSkuReportRoutes,
                ...DistrictWiseReportRoutes,
                ...DailyProductWiseSalesRoutes,
                ...DailyProductWisePurchaseRoutes,
                ...BatchWiseSkuWiseReportRoutes,
                ...utilizedAmountImportRoutes,
                ...batchClosingStockRoute,
                ...StateWiseRateRoute,
                ...tallyReportRout,
                ...salesReportRoute,
                ...TransactionReportRoute,
                ...AdditionalInwardRoutes,
                ...OrderRevertRoute,
                ...StockauditNewRoutes,
                ...WorkOrderNewRoutes,
            ],
        },
        ...sessionRoutes,
        {
            path: '*',
            element: <NotFound />,
        },
    ]

    return all_routes
}
