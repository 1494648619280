import useAuth from 'app/hooks/useAuth'

// export const navigations = [
//     {
//         name: 'Dashboard',
//         path: '/dashboard/default',
//         icon: 'dashboard',
//     },
//     {
//         label: 'PAGES',
//         type: 'label',
//     },
//     {
//         name: 'Master',
//         icon: 'track_changes',
//         children: [
//             {
//                 name: 'State',
//                 icon: 'location_on',
//                 path: '/states',

//             },
//             {
//                 name: 'City',
//                 icon: 'location_city',
//                 path: '/cities',

//             },
//             {
//                 name: 'Role Master',
//                 icon: 'group_add',
//                 path: '/rolemaster',

//             },
//             {
//                 name: 'Yard',
//                 icon: 'airplay',
//                 path: '/yard',

//             },
//             {
//                 name: 'Menu Master',
//                 icon: 'create_new_folder',
//                 path: '/menumaster',

//             },
//             {
//                 name: 'Dashobard Menu Master',
//                 icon: 'create_new_folder',
//                 path: '/dashobard-menumaster',

//             },
//             {
//                 name: 'User Access Menu',
//                 icon: 'folder_shared',
//                 path: '/AccessManagement',

//             },
//             {
//                 name: 'UOM Master',
//                 icon: 'business_center',
//                 path: '/uommaster',

//             },
//             {
//                 name: 'Product Group',
//                 icon: 'iso',
//                 path: '/skutypenew',

//             },
//             {
//                 name: 'SKU Properties',
//                 icon: 'layers',
//                 path: '/skuproperties',

//             },
//             {
//                 name: 'SKU Properties Dropdown',
//                 icon: 'layers',
//                 path: '/skupropertiesdropdown',

//             },
//             {
//                 name: 'SKU Mapping',
//                 icon: 'beenhere',
//                 path: '/skumapping',

//             },
//             {
//                 name: 'SKU Master',
//                 icon: 'layers',
//                 path: '/SkuMasters',

//             },
//             {
//                 name: 'Payment Terms',
//                 icon: 'payment',
//                 path: '/paymentterms',

//             },
//             {
//                 name: 'Trigger Master',
//                 icon: 'flash_on',
//                 path: '/triggermaster',

//             },
//             {
//                 name: 'Zone Master',
//                 icon: 'travel_explore',
//                 path: '/Zonemaster',

//             },
//             {
//                 name: 'Country Master',
//                 icon: 'FlagCircle',
//                 path: '/Countrymaster',

//             },
//             {
//                 name: 'District Master',
//                 icon: 'FlagCircle',
//                 path: '/Districtmaster',

//             },

//         ],
//     },
//     {
//         name: 'Employee',
//         icon: 'people',
//         path: '/employee',

//     },
//     {
//         name: 'Customer',
//         icon: 'person',
//         path: '/customer',

//     },
//     {
//         name: 'Order',
//         icon: 'access_time',
//         path: '/order',

//     },
//     {
//         name: 'Stock Inward',
//         icon: 'access_time',
//         path: '/stockinward',

//     },
//     {
//         name: 'View Stock',
//         icon: 'timeline',
//         path: '/ViewStock',

//     },
//     {
//         name: 'Dump Stock',
//         icon: 'timeline',
//         path: '/dump-stock',

//     },
//     {
//         name: 'Batch Closing',
//         icon: 'timeline',
//         path: '/batch-closing',

//     },
//     {
//         name: 'Logistcs',
//         icon: 'timeline',
//         path: '/logistics',

//     },
//     {
//         name: 'Invoice',
//         icon: 'timeline',
//         path: 'CreateInvoice',

//     },
//     {
//         name: 'Intergodown Transfer',
//         icon: 'create',
//         path: '/IntergodownTransfer',

//     },
// ]
export const navigations = (menulist = []) => {
    var menus=[{
        name: 'Dashboard',
        path: '/dashboard/default',
        icon: 'dashboard',
    }]
    if(menulist){
        menulist.map((menu,i)=>{
            var singlemenu={
                name: menu.menuName,
                path: menu.menu_page_url,
                icon: menu.icon,
            }
            if(menu.children.length>0){
                var chilmenu=[];
                menu.children.map((chmenu,i)=>{
                    chilmenu.push({
                        name: chmenu.menuName,
                        path: chmenu.menu_page_url,
                        icon: chmenu.icon,
                    })
                })
                singlemenu['children']=chilmenu
            }
            menus.push(singlemenu)
        })
    }

    return menus;
    return [
        {
            name: 'Dashboard',
            path: '/dashboard/default',
            icon: 'dashboard',
        },
        {
            label: 'PAGES',
            type: 'label',
        },
        {
            name: 'Master',
            icon: 'track_changes',
            children: [
                {
                    name: 'State',
                    icon: 'location_on',
                    path: '/states',
                },
                {
                    name: 'City',
                    icon: 'location_city',
                    path: '/cities',
                },
                {
                    name: 'Role Master',
                    icon: 'group_add',
                    path: '/rolemaster',
                },
                {
                    name: 'Yard',
                    icon: 'airplay',
                    path: '/yard',
                },
                {
                    name: 'Menu Master',
                    icon: 'create_new_folder',
                    path: '/menumaster',
                },
                {
                    name: 'Dashobard Menu Master',
                    icon: 'create_new_folder',
                    path: '/dashobard-menumaster',
                },
                {
                    name: 'User Access Menu',
                    icon: 'folder_shared',
                    path: '/AccessManagement',
                },
                {
                    name: 'UOM Master',
                    icon: 'business_center',
                    path: '/uommaster',
                },
                {
                    name: 'Product Group',
                    icon: 'iso',
                    path: '/skutypenew',
                },
                {
                    name: 'SKU Properties',
                    icon: 'layers',
                    path: '/skuproperties',
                },
                {
                    name: 'SKU Properties Dropdown',
                    icon: 'layers',
                    path: '/skupropertiesdropdown',
                },
                {
                    name: 'SKU Mapping',
                    icon: 'beenhere',
                    path: '/skumapping',
                },
                {
                    name: 'SKU Master',
                    icon: 'layers',
                    path: '/SkuMasters',
                },
                {
                    name: 'Payment Terms',
                    icon: 'payment',
                    path: '/paymentterms',
                },
                {
                    name: 'Trigger Master',
                    icon: 'flash_on',
                    path: '/triggermaster',
                },
                {
                    name: 'Zone Master',
                    icon: 'travel_explore',
                    path: '/Zonemaster',
                },
                {
                    name: 'Country Master',
                    icon: 'FlagCircle',
                    path: '/Countrymaster',
                },
                {
                    name: 'District Master',
                    icon: 'FlagCircle',
                    path: '/Districtmaster',
                },
            ],
        },
        {
            name: 'Report Master',
            icon: 'track_changes',
            children: [
                {
                    name: 'Product SKU wise Report',
                    icon: 'location_on',
                    path: '/ProductWiseReport',
                },
                {
                    name: 'DO Log wise Report',
                    icon: 'location_on',
                    path: '/ProductSkuReport',
                },
                {
                    name: 'District Wise Report',
                    icon: 'location_on',
                    path: '/DistrictWiseReport',
                },
                {
                    name: 'Daily Product-Wise Sales ',
                    icon: 'location_on',
                    path: '/DailyProductWiseSalesReport',
                },
                {
                    name: 'Daily Product-Wise Purchase ',
                    icon: 'location_on',
                    path: '/DailyProductWisePurchaseReport',
                },
                {
                    name: 'BatchWise & SkuWise ',
                    icon: 'location_on',
                    path: '/BatchWise&SkuWiseReport',
                },
                {
                    name: 'Batch Closing Stock ',
                    icon: 'location_on',
                    path: '/BatchClosingStock',
                },
                {
                    name: 'Tally Report',
                    icon: 'location_on',
                    path: '/tallyReport',
                },
                {
                    name: 'Sales Report',
                    icon: 'location_on',
                    path: '/salesReport',
                },
            ],
        },
        {
            name: 'Employee',
            icon: 'people',
            path: '/employee',
        },
        {
            name: 'Customer',
            icon: 'person',
            path: '/customer',
        },
        {
            name: 'Vendor Users',
            icon: 'person',
            path: '/vendor-users',
        },
        {
            name: 'Supplier Users',
            icon: 'person',
            path: '/supplier-users',
        },
        {
            name: 'Order',
            icon: 'access_time',
            path: '/order',
        },
        {
            name: 'Stock Inward',
            icon: 'access_time',
            path: '/stockinward',
        },
        {
            name: 'View Stock',
            icon: 'timeline',
            path: '/ViewStock',
        },
        {
            name: 'Dump Stock',
            icon: 'timeline',
            path: '/dump-stock',
        },
        {
            name: 'Batch Closing',
            icon: 'timeline',
            path: '/batch-closing',
        },
        {
            name: 'Logistcs',
            icon: 'timeline',
            path: '/logistics',
        },
        {
            name: 'Invoice',
            icon: 'timeline',
            path: 'CreateInvoice',
        },
        {
            name: 'Intergodown Transfer',
            icon: 'create',
            path: '/IntergodownTransfer',
        },
        {
            name: 'Sales Return',
            icon: 'create',
            path: '/sales-return',
        },
        {
            name: 'Work Order',
            icon: 'create',
            path: '/work-order',
        },
        {
            name: 'Yard Work Order',
            icon: 'create',
            path: '/yard-work-order',
        },
        {
            name: 'Credit Limit',
            icon: 'create',
            path: '/credit-limit',
        },
        {
            name: 'Customer Reciepts',
            icon: 'create',
            path: '/customer-reciept',
        },
        {
            name: 'Utilized Amount Import',
            icon: 'create',
            path: '/utilized-amount',
        },
    ]
}
// export const navigations = [
//     {
//         name: 'Dashboard',
//         path: '/dashboard/default',
//         icon: 'dashboard',
//     },
//     {
//         label: 'PAGES',
//         type: 'label',
//     },
//     {
//         name: 'Master',
//         icon: 'track_changes',
//         children: [
//             {
//                 name: 'State',
//                 icon: 'location_on',
//                 path: '/states',

//             },
//             {
//                 name: 'City',
//                 icon: 'location_city',
//                 path: '/cities',

//             },
//             {
//                 name: 'Role Master',
//                 icon: 'group_add',
//                 path: '/rolemaster',

//             },
//             {
//                 name: 'Yard',
//                 icon: 'airplay',
//                 path: '/yard',

//             },
//             {
//                 name: 'Menu Master',
//                 icon: 'create_new_folder',
//                 path: '/menumaster',

//             },
//             {
//                 name: 'Dashobard Menu Master',
//                 icon: 'create_new_folder',
//                 path: '/dashobard-menumaster',

//             },
//             {
//                 name: 'User Access Menu',
//                 icon: 'folder_shared',
//                 path: '/AccessManagement',

//             },
//             {
//                 name: 'UOM Master',
//                 icon: 'business_center',
//                 path: '/uommaster',

//             },
//             {
//                 name: 'Product Group',
//                 icon: 'iso',
//                 path: '/skutypenew',

//             },
//             {
//                 name: 'SKU Properties',
//                 icon: 'layers',
//                 path: '/skuproperties',

//             },
//             {
//                 name: 'SKU Properties Dropdown',
//                 icon: 'layers',
//                 path: '/skupropertiesdropdown',

//             },
//             {
//                 name: 'SKU Mapping',
//                 icon: 'beenhere',
//                 path: '/skumapping',

//             },
//             {
//                 name: 'SKU Master',
//                 icon: 'layers',
//                 path: '/SkuMasters',

//             },
//             {
//                 name: 'Payment Terms',
//                 icon: 'payment',
//                 path: '/paymentterms',

//             },
//             {
//                 name: 'Trigger Master',
//                 icon: 'flash_on',
//                 path: '/triggermaster',

//             },
//             {
//                 name: 'Zone Master',
//                 icon: 'travel_explore',
//                 path: '/Zonemaster',

//             },
//             {
//                 name: 'Country Master',
//                 icon: 'FlagCircle',
//                 path: '/Countrymaster',

//             },
//             {
//                 name: 'District Master',
//                 icon: 'FlagCircle',
//                 path: '/Districtmaster',

//             },

//         ],
//     },
//     {
//         name: 'Employee',
//         icon: 'people',
//         path: '/employee',

//     },
//     {
//         name: 'Customer',
//         icon: 'person',
//         path: '/customer',

//     },
//     {
//         name: 'Vendor Users',
//         icon: 'person',
//         path: '/vendor-users',

//     },
//     {
//         name: 'Order',
//         icon: 'access_time',
//         path: '/order',

//     },
//     {
//         name: 'Stock Inward',
//         icon: 'access_time',
//         path: '/stockinward',

//     },
//     {
//         name: 'View Stock',
//         icon: 'timeline',
//         path: '/ViewStock',

//     },
//     {
//         name: 'Dump Stock',
//         icon: 'timeline',
//         path: '/dump-stock',

//     },
//     {
//         name: 'Batch Closing',
//         icon: 'timeline',
//         path: '/batch-closing',

//     },
//     {
//         name: 'Logistcs',
//         icon: 'timeline',
//         path: '/logistics',

//     },
//     {
//         name: 'Invoice',
//         icon: 'timeline',
//         path: 'CreateInvoice',

//     },
//     {
//         name: 'Intergodown Transfer',
//         icon: 'create',
//         path: '/IntergodownTransfer',

//     },
//     {
//         name: 'Sales Return',
//         icon: 'create',
//         path: '/sales-return',

//     },
//     {
//         name: 'Work Order',
//         icon: 'create',
//         path: '/work-order',

//     },
//     {
//         name: 'Yard Work Order',
//         icon: 'create',
//         path: '/yard-work-order',

//     },
//     {
//         name: 'Credit Limit',
//         icon: 'create',
//         path: '/credit-limit',

//     },
//     {
//         name: 'Customer Reciepts',
//         icon: 'create',
//         path: '/customer-reciept',

//     },
// ]
